enum WebsiteName {
  BRAVO = 'BRAVO',
  DELTA = 'DELTA',
  ASEAN = 'ASEAN',
  GAME = 'GAME',
  DIVA = 'DIVA',
  ALFA = 'ALFA',
}

export default WebsiteName;
